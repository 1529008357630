import React from 'react';
import { HeroBanner, Container } from '@hpstellar/core/visId';
import withPageComponents from '../../../page/components/with-page-components';
import { useHeroBannerProps } from '@hpstellar/core/visId/HeroBanner';
import { useInnovationBannerProps } from '@hpstellar/core/visId/InnovationBanner';

import { html } from '../../../utility/setHtml';

import './hero-banner.less';

const getInnovationBannerWithHtml = innovationBanner => {
    return {
        ...innovationBanner,
        title: html(innovationBanner.title),
        description: html(innovationBanner.description),
        disclaimer: html(innovationBanner.disclaimer),
    };
};

const getHeroBannerContent = slides =>
    slides.map(slide => {
        const { titleTypographyProps, subtitleTypographyProps } = slide;
        //TODO: default tags for title and subtitle when unset. See if GFE team can fix this in the hook
        if (titleTypographyProps && !titleTypographyProps.tag) {
            titleTypographyProps.tag = 'h2';
        }
        if (subtitleTypographyProps && !subtitleTypographyProps.tag) {
            subtitleTypographyProps.tag = 'h2';
        }
        // check if there is innovation banner
        if ('InnovationBannerProps' in slide) {
            const slideWithInnovationProps = useInnovationBannerProps(slide['InnovationBannerProps']);
            const slideInnovationPropsWithHtml = getInnovationBannerWithHtml(slideWithInnovationProps);

            return {
                ...slide,
                title: html(slide.title),
                subtitle: html(slide.subtitle),
                description: html(slide.description),
                disclaimer: html(slide.disclaimer),
                InnovationBannerProps: slideInnovationPropsWithHtml,
            };
        }

        return {
            ...slide,
            title: html(slide.title),
            subtitle: html(slide.subtitle),
            description: html(slide.description),
            disclaimer: html(slide.disclaimer),
        };
    });

const heroBanner = ({ homeHeroBanner }) => {
    if (!homeHeroBanner) return null;

    let bannerProps = {};
    bannerProps['slides'] = homeHeroBanner;
    const heroBannerProps = useHeroBannerProps(bannerProps);
    const { slides } = heroBannerProps;
    const slidesWithHTMLProps = getHeroBannerContent(slides);

    if (homeHeroBanner && homeHeroBanner.length > 0) {
        return (
            <>
                <HeroBanner className="homeHeroBanner" slides={slidesWithHTMLProps} />
            </>
        );
    }
    return null;
};

export default withPageComponents(heroBanner, { components: ['homeHeroBanner'] });
