import React from 'react';

import { InnovationBanner, Grid, Container } from '@hpstellar/core/visId';
import { useInnovationBannerProps } from '@hpstellar/core/visId/InnovationBanner';

import withPageComponents from '../../../page/components/with-page-components';
import usePromotionImpressions from '../../../hooks/usePromotionImpressions';
export { default as SecondaryBannerLoader } from './skeleton-loader';

import './secondary-banner.less';

const secondaryHomeBanner = ({ secondaryBanner, analyticsData }) => {
    if (!secondaryBanner || !secondaryBanner?.banners) return null;
    const { banners } = secondaryBanner;

    const bannerPropsList = banners.map(banner => {
        const bannerWithInnovationProps = useInnovationBannerProps(banner);
        try {
            if (banner && bannerWithInnovationProps) {
                const gtmProps = banner.gtmActions.get('ctaClick');
                const { gtmId, gtmValue, gtmCategory } = gtmProps || {};
                if (gtmId && gtmValue && gtmCategory) {
                    bannerWithInnovationProps.ButtonProps = {
                        ...(banner.ButtonProps || {}),
                        'data-gtm-id': gtmId,
                        'data-gtm-value': gtmValue,
                        'data-gtm-category': gtmCategory,
                    };
                }
            }
        } catch (e) {}
        return bannerWithInnovationProps;
    });

    usePromotionImpressions({
        data: [secondaryBanner],
        id: 'secondaryBanner',
        analyticsData,
        componentKey: 'secondaryBanner',
    });

    const addTitleTag = content => {
        const titleTag = 'h2';
        return {
            ...content,
            titleTypographyProps: {
                tag: titleTag,
            },
        };
    };

    const bannerx2Props = content => {
        return {
            ...content,
            titleTypographyProps: {
                tag: 'h1',
            },
            labelTypographyProps: {
                tag: 'h4',
            },
            descriptionTypographyProps: {
                tag: 'h4',
            },
        };
    };

    if (banners.length === 1) {
        const bannerProps = addTitleTag(bannerPropsList[0]);
        return <InnovationBanner className="secondaryBanner" {...bannerProps} />;
    }

    return (
        <Container className="multiBanner">
            <Grid container>
                {bannerPropsList.map((b, index) => (
                    <Grid key={`shBanner-${index}`} className="gridItem" col xs={12} sm={12} md={6} lg={6}>
                        <InnovationBanner key={`ib${index}`} {...bannerx2Props(addTitleTag(b))} />
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default withPageComponents(secondaryHomeBanner, { components: ['secondaryBanner'], analyticsData: true });
